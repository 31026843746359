// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC0X0Alo5ffIk4PtlG3p3HSlw4UGaD-7h4",
    authDomain: "recapper-cc17b.firebaseapp.com",
    projectId: "recapper-cc17b",
    storageBucket: "recapper-cc17b.appspot.com",
    messagingSenderId: "619224143533",
    appId: "1:619224143533:web:05e7f18fdb8d3433c01824",
    measurementId: "G-MMQ49SKRD6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and check if supported
let analytics;
isSupported().then((supported) => {
    if (supported) {
        analytics = getAnalytics(app);
        console.log("Firebase Analytics initialized successfully.");
    } else {
        console.log("Firebase Analytics is not supported on this device.");
    }
}).catch((error) => {
    console.error("Error checking Firebase Analytics support: ", error);
});

// Export the app and analytics
export { app, analytics };
